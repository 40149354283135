
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  isRestClientError,
  JpuDTO,
  storeErrorsMapping,
  SubscriptionActionRequest,
  SubscriptionDTO,
  SubscriptionTypes,
} from "@jfrog-ba/myjfrog-common";
import { AnimatedContentCardProps } from "@/types/content/animatedContentCard";
import { LoadingMaskProps } from "@/types/loadingMask";
import { FlatSubscription } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration } from "@/types/services/menus";
import LoadingMask from "@/components/common/LoadingMask.vue";
import AnimatedContentCard from "@/components/content/AnimatedContentCard.vue";
import PageSection from "@/components/layout/PageSection.vue";
import PageView from "@/components/layout/PageView.vue";
import { FloatingNotificationTagName } from "@/services/floatingNotifications";

@Component({
  name: "SubscriptionsAddPipelines",
  components: {
    PageView,
    PageSection,
    LoadingMask,
    AnimatedContentCard,
  },
})
export default class SubscriptionsAddPipelines extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("setMenuConfiguration", { namespace: "application" })
  setMenuConfiguration!: (menuConfiguration: MenuConfiguration) => void;
  @Action("setViewContext", { namespace: "application" })
  setViewContext!: (viewContext: ViewContext) => void;
  @Getter("menuConfiguration", { namespace: "application" })
  menuConfiguration!: MenuConfiguration;
  accountNumber!: FlatSubscription["accountNumber"];
  subscription: FlatSubscription | null = null;
  price: string | null = null;
  pipelinesPriceForMinute: string | undefined = undefined;
  monthlyBuildMinutes: string | undefined = undefined;
  contentCards: AnimatedContentCardProps[] = [];
  videoHeight = 200;
  contentHeight: number = 100;
  contentHeightUnit: "px" | "%" = "%";
  loading = false;
  @Getter("subscriptions", { namespace: "subscriptions" })
  subscriptions!: SubscriptionDTO[];
  @Action("setSubscriptions", { namespace: "subscriptions" })
  setSubscriptions!: (subscriptions: SubscriptionDTO[]) => void;

  @Watch("subscription")
  async onSubscriptionChanged() {
    this.setMenuConfiguration(await this.defineMenuConfiguration());
    this.setViewContext(this.defineViewContext());
  }

  defineViewContext(): ViewContext {
    return this.subscriptions && this.subscription
      ? {
          subscriptionMetas: this.subscriptions.map(s => s.meta),
          currentSubscriptionMeta: this.subscription.meta,
          filteredNotifications: [FloatingNotificationTagName.PIPELINES_TEASER],
        }
      : {};
  }

  get nbCardsPerRows() {
    return this.isMobile ? 1 : this.isTablet ? 2 : 3;
  }

  get prefixTitle() {
    if (!this.subscription) {
      return "";
    }
    return "ADD";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
  get isMobile() {
    return this.$mq === "mobile";
  }
  get isLaptop() {
    return this.$mq === "laptop";
  }
  get isLaptopXL() {
    return this.$mq === "laptopXL";
  }

  get titleWrapperStyles() {
    // const flexVal: number = this.isLaptop ? 1.7 : this.isLaptopXL ? 1.3 : this.isMobile ? 0 : 1;
    const flexVal: number = this.isLaptop ? 1.5 : this.isTablet ? 1.8 : this.isMobile ? 0 : 1;
    return {
      flex: flexVal,
    };
  }

  get sectionClasses() {
    const classes = [];
    classes.push((!this.isMobile && "noscroll") || "");
    return classes;
  }
  get contentWrapperClasses() {
    const classes = [];
    classes.push((!this.isMobile && "scrollable") || "");
    return classes;
  }

  get contentWrapperStyles() {
    return {
      height: this.contentHeight + this.contentHeightUnit,
    };
  }

  get cardStyles() {
    const flexPercent = 100 / this.nbCardsPerRows;
    return {
      flex: flexPercent + "%",
    };
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get isLoading() {
    return !this.price || !this.subscription || this.loading;
  }

  get serverName() {
    return (this.subscription && this.subscription.serverName) || "";
  }

  async defineMenuConfiguration(): Promise<MenuConfiguration> {
    this.globalBus.$emit("onMenuConfigurationChange");
    if (this.subscription) {
      const meta = this.subscription.meta;
      return await this.$jfMenus.defineContextualMenuConfiguration(
        this.serverName,
        meta,
        (menuId, serverName, tooltipContent, tooltipHasSubmitAction) => {
          this.globalBus.$emit(
            "onMenuTooltipClick",
            menuId,
            serverName,
            tooltipContent,
            tooltipHasSubmitAction,
            this.$jfSubscriptions.getSubscriptionRequestReason(menuId, meta),
          );
        },
      );
    }
    return {};
  }

  notFound() {
    this.$router.replace({ path: "/404" });
  }

  notAllowed() {
    this.$router.replace({ path: "/" });
  }

  get enterpriseReady(): AnimatedContentCardProps {
    return {
      title: "Enterprise-Ready",
      content: [
        "Scales horizontally, allowing you to have a centrally-managed solution that supports thousands of users and pipelines in a high-availability (HA) environment.",
      ],
      videoUrl: this.$jfVideos.get("Centrally_Manage_R2_1.mp4"),
      videoPoster: this.$jfImages.get("poster_enterprise-ready.png"),
      height: this.videoHeight,
    };
  }

  get nativeSteps(): AnimatedContentCardProps {
    return {
      title: "Native Steps",
      content: [
        `Pre-packaged declarative steps with no scripting required, making it easy to create complex pipelines, including cross-team "pipelines of pipelines."`,
      ],
      videoUrl: this.$jfVideos.get("native_steps_r1_animation_3.mp4"),
      videoPoster: this.$jfImages.get("poster_native-steps.png"),
      height: this.videoHeight,
    };
  }

  get comprehensiveIntegrations(): AnimatedContentCardProps {
    return {
      title: "Comprehensive Integrations",
      content: [
        "Integrates with most DevOps tools. The steps in a single pipeline can run on multi-OS, multi-architecture nodes, reducing the need to have multiple CI/CD tools.",
      ],
      videoUrl: this.$jfVideos.get("universal_R10.mp4"),
      videoPoster: this.$jfImages.get("poster_universal_R10.jpg"),
      height: this.videoHeight,
    };
  }

  get securityFirst(): AnimatedContentCardProps {
    return {
      title: "Security-First",
      content: [
        "Uses fine-grained permissions and access control with centralized secret management. Each step in a pipeline executes in its own isolated build node.",
      ],
      videoUrl: this.$jfVideos.get("Intitlement_R2.mp4"),
      videoPoster: this.$jfImages.get("poster_security-first.png"),
      height: this.videoHeight,
    };
  }

  get realTimeVisibility(): AnimatedContentCardProps {
    return {
      title: "Real-Time Visibility",
      content: [
        "Real-time interactive dashboard with alerts and notifications to easily identify and escalate bottlenecks and failures.",
      ],
      videoUrl: this.$jfVideos.get("realtime_vul_r1_animation_2.mp4"),
      videoPoster: this.$jfImages.get("poster_real-time-visibility.png"),
      height: this.videoHeight,
    };
  }
  get pipelinesAsCode(): AnimatedContentCardProps {
    return {
      title: "Pipelines-as-Code",
      content: [
        "Easy-to-learn YAML syntax that is standardized across the pipeline steps. The configurations are versioned, modular, reusable, and modern.",
      ],
      videoUrl: this.$jfVideos.get("smartScripting_R2_1.mp4"),
      videoPoster: this.$jfImages.get("poster_smartScripting_R2_1.jpg"),
      height: this.videoHeight,
    };
  }

  async loadContent() {
    await this.fetchSubscription();
    await this.loadStartingPrice();
    this.contentCards = [
      this.enterpriseReady,
      this.nativeSteps,
      this.comprehensiveIntegrations,
      this.securityFirst,
      this.realTimeVisibility,
      this.pipelinesAsCode,
    ];
    this.contentHeight = (document.getElementsByClassName("ex-page-wrapper")[0] as HTMLDivElement).clientHeight;
    this.contentHeightUnit = "px";
  }

  async loadStartingPrice() {
    if (!this.subscription) {
      return;
    }
    const types = await this.$jfSubscriptions.getSubscriptionTypes(this.accountNumber);
    let currentSubscriptionType: SubscriptionTypes | undefined;
    if (this.subscription.meta.isCloudPro) {
      currentSubscriptionType = types.find(type => type.optionType === "PIPELINES_PRO");
    } else if (this.subscription.meta.isCloudProX) {
      currentSubscriptionType = types.find(type => type.optionType === "PIPELINES_PRO_X");
    } else if (this.subscription.meta.isEnterprise) {
      currentSubscriptionType = types.find(type => type.optionType === "PIPELINES_ENT");
    }

    this.price = currentSubscriptionType ? this.$utils.round(parseInt(currentSubscriptionType.price), 0) : "N/A";
    this.pipelinesPriceForMinute = currentSubscriptionType ? currentSubscriptionType.pipelinesPriceForMinute : "N/A";
    this.monthlyBuildMinutes = currentSubscriptionType ? currentSubscriptionType.monthlyBuildMinutes : "N/A";
  }

  async fetchSubscription() {
    try {
      const subscription = await this.$jfSubscriptions.getSubscription(this.accountNumber);
      const meta = subscription.meta;
      const subscriptions = await this.$jfSubscriptions.getSubscriptions();
      const subscriptionMetas = subscriptions.map(s => s.meta);
      if (!this.$jfSubscriptions.isAddPipelinesPageReachable(meta)) {
        return this.notAllowed();
      }
      this.subscription = this.$jfSubscriptions.transformSubscription(subscription);
    } catch (error) {
      if (error.httpStatusText === 500) {
        this.$jfNotification.error({ text: this.$jfMessages.app_something_went_wrong });
      } else {
        this.notFound();
      }
    }
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
  }

  async sendAppPipelinesRequestToMarketo(
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    await this.$jfSubscriptions.sendSubscriptionActionRequest({
      marketoCookie: this.$jfMarketo.getCookie(),
      actionType: "upgrade",
      serverName: serverName,
      reason: reason,
    });
  }
  async addPipelines() {
    if (!this.subscription) {
      return;
    }
    try {
      this.loading = true;
      const subscriptionDTO = await this.$jfSubscriptions.updateProducts(
        this.subscription.accountNumber,
        new Date(),
        ["pipelines"],
        [],
      );

      await this.setSubscriptions(
        this.subscriptions.map(subscription =>
          subscriptionDTO.accountNumber === subscription.accountNumber ? subscriptionDTO : subscription,
        ),
      );

      this.$jfNotification.success({ text: this.$jfMessages.subscriptions_add_pipelines_success });
      this.$router.push({ path: `/subscriptions`, params: { accountNumber: "" + this.subscription.accountNumber } });
    } catch (e) {
      this.$log.error(e);
      let errorMessage = this.$jfMessages.subscriptions_add_pipelines_error_unknown;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.addOptionalProducts.insufficientBalance) {
          errorMessage = this.$jfMessages.subscriptions_add_pipelines_error_insufficient_balance;
        }
      }

      this.notifyError(errorMessage);
    } finally {
      this.loading = false;
      this.$emit("loading", false);
      this.$jfModal.dismiss();
    }
  }

  handleUpgradeClick() {
    if (!this.subscription) {
      return;
    }

    let metas = this.subscription.meta;
    const unableToAddPipelinesExplanation = this.$jfSubscriptions.getIsUnableToAddPipelinesExplanation(metas);
    if (unableToAddPipelinesExplanation) {
      this.sendAppPipelinesRequestToMarketo(this.serverName, unableToAddPipelinesExplanation.reason);
      this.$jfNotification.success({ text: unableToAddPipelinesExplanation.message });
      return;
    }

    const confirmMessage =
      metas.isPrepaidPayment ||
      metas.isEnterprise ||
      metas.isEnterpriseX ||
      metas.isEnterprisePlus ||
      metas.isEnterprisePlusTrial
        ? `Please confirm adding CI/CD Pipelines to your ${this.subscription.displayName} subscription. Subject to the fees listed herein and the applicable Terms of Use.`
        : `Please confirm adding CI/CD Pipelines to your ${this.subscription.displayName} subscription.`;

    this.$jfModal.confirm({
      body: `<div text-left>${confirmMessage}</div>`,
      buttonsText: {
        confirm: "Confirm",
      },
      onConfirm: () => {
        this.addPipelines();
        return true;
      },
      title: "CI/CD PIPELINES",
      headerBorder: false,
      displayCancelButton: false,
      footerBorder: false,
    });
  }
  mounted() {
    this.$jfModal.closeAll();
    const queryParam = parseInt(this.$route.params.accountNumber) as SubscriptionDTO["accountNumber"];
    if (Number.isNaN(queryParam)) {
      this.notFound();
    } else {
      this.accountNumber = queryParam;
      this.loadContent();
    }
  }
}
